import Typewriter from 'typewriter-effect/dist/core';

const typewrite = document.querySelector('.typewrite')

if (typewrite !== null) {
    new Typewriter(typewrite, {
        strings: window.TYPED_WORDS,
        autoStart: true,
        loop: true
    });
}
