$(() => {
    $('.btn-dropdown[data-group]').click(function() {
        const $this = $(this)
        const $icon = $this.find('.mi')
        const group = $this.data('group')
        const $submenu = $(`.submenu[data-group="${group}"]`)
        $submenu.toggleClass('closed')
        $icon.css('transform', 'rotate(180deg)')
    })
})
